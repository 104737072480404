import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { getPatientRecord, postPatientRecord } from '../../actions/assessmentInfo';
import { getUrlParams } from '../../helpers/location';
import { setSkipAi } from '../../actions/assessmentInfo';
import { createLoadingSelector } from '../../reducers/api';
import useActiveSession from '../../hooks/useActiveSession';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Layout from '../../components/Layout';
import PersonalInfo from '../../components/Assessment/PersonalInfo';

export default ({ location }) => {
  const dispatch = useDispatch();
  const params = getUrlParams(location.search);
  const loading = useSelector(createLoadingSelector(['assessment/patient/post']));

  useActiveSession(() => {
    dispatch(setSkipAi(!!params.skipAi));
    dispatch(getPatientRecord());
  }, []);

  const nextStep = async (value) => {
    try {
      await dispatch(postPatientRecord(value));
      navigate(`/assessment/vitalSignInfo`);
    } catch (e) {
    }
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <PersonalInfo loading={loading} onSubmit={nextStep} ignoreFields={{ phone: 1 }}/>
      </Box>
    </Layout>
  );
}

